import React from 'react';
import ContactForm from './contacts/ContactForm';
import ContactAddress from './contacts/ContactAddress';

export default function ContactUs() {
  return (
    <>
    <div className='Inner-banner'>
        <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/inner-banner-05.jpg`} alt="Home Visits" />
      </div>
      <div className='container contact-page mt-5 mb-5'>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6">
            <ContactForm />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 contact-address-section">          
              <ContactAddress />
          </div>
        </div>
      </div>

    </>
  );
}
