import React from 'react';
import { Link } from 'react-router-dom';
export default function ClinicAudiometry() {
  return (
    <>
        <div className='services-item container'>
            <div className='row'>
                <div className='col-lg-6 col-md-6 col-sm-12 v-align-middle mob-order-2 p-0'>
                    <div className='services-item-content'>
                    <h2><span>Audiometry</span></h2>          
                    <p>At FamPhy, we offer comprehensive audiometry services to evaluate your hearing abilities and identify any potential hearing loss. Our audiometry tests are designed to provide you with accurate assessments and tailored recommendations to maintain your auditory health. Whether you’re experiencing hearing difficulties or seeking a routine check-up, our professional team is here to help.</p>
                    <Link to="/contacts" className="btn theam-btn-m">Contact Us</Link>
                    </div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 mob-order-1 p-0'>
                <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/clinic-visit-04.png`} alt="Home Visits" />
                </div>
            </div>
        </div>
    </>
  );
}
