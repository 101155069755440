// src/utils/httpClient.js
import axios from 'axios';

const httpClient = axios.create({
    baseURL: 'https://www.famphy.com/api/', // Set your base URL
    timeout: 10000, // Optional: Set a request timeout
    headers: {
        'Content-Type': 'application/json',
    },
});

// Interceptors for request or response handling (Optional)
httpClient.interceptors.request.use(
    (config) => {
        // Add auth token or modify headers if needed
        // config.headers['Authorization'] = `Bearer ${token}`;
        return config;
    },
    (error) => Promise.reject(error)
);

httpClient.interceptors.response.use(
    (response) => response,
    (error) => {
        // Handle errors globally, like logging out if the token is invalid
        return Promise.reject(error);
    }
);

const get = (url, config = {}) => httpClient.get(url, config);
const post = (url, data, config = {}) => httpClient.post(url, data, config);
const put = (url, data, config = {}) => httpClient.put(url, data, config);
const del = (url, config = {}) => httpClient.delete(url, config);

export { get, post, put, del };
