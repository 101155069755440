import React from 'react';

import ExcellenceMedicalService from './aboutus/ExcellenceMedicalService';
import FamphyLeadershipTeam from './aboutus/FamphyLeadershipTeam';

export default function AboutUs() {
  return (
    <>
    <div className='Inner-banner'>
        <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/inner-banner-03.jpg`} alt="Home Visits" />
      </div>
      <div className='aboutus-page'>
        <ExcellenceMedicalService />
        <FamphyLeadershipTeam />
      </div>
    </>
  );
}
