import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

export default function OurTeam() {

  const carouselRef = useRef(null);

  useEffect(() => {
    const handleSlide = (e) => {
      const target = e.relatedTarget;
      const idx = Array.from(target.parentNode.children).indexOf(target);
      const itemsPerSlide = 5;
      const totalItems = document.querySelectorAll('.carousel-item').length;

      if (idx >= totalItems - (itemsPerSlide - 1)) {
        const it = itemsPerSlide - (totalItems - idx);
        for (let i = 0; i < it; i++) {
          if (e.direction === 'left') {
            const item = document.querySelectorAll('.carousel-item')[i];
            document.querySelector('.carousel-inner').appendChild(item);
          } else {
            const item = document.querySelectorAll('.carousel-item')[0];
            document.querySelector('.carousel-inner').appendChild(item);
          }
        }
      }
    };

    const carouselElement = carouselRef.current;
    carouselElement.addEventListener('slide.bs.carousel', handleSlide);

    return () => {
      carouselElement.removeEventListener('slide.bs.carousel', handleSlide);
    };
  }, []);


  return (
    <>
      <div className="container">
        <div className='ourteam-section'>
          <h2>Our Team of <span>Doctors</span></h2>
          <p className='limit-width'>Specialized and general physicians who have many years of work experience in there respective fields</p>
          

      
          <div
            id="carouselTeam"
            className="carousel slide"
            data-bs-ride="carousel"
            data-bs-interval="9000"
            ref={carouselRef}
          >
          <div className="carousel-inner">
           
            <div className="carousel-item active">
              <div className="row">              
                <div className="col-md-6">
                  <div className='shadow-box row'>
                    <div className="col-lg-5 col-md-5 col-sm-5 teamimg">
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/famphy-team03.png`} alt="slide 3" />
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-7 teamcontent">
                      <h5>Dr. P.K. Srivastava</h5>
                      <p>30+ years of experience in Dermatology and Cosmetology across severabhospitals in India</p>
                      <div className='ext-content'>
                        <span className='exp-yers'>30+ Years of Exp</span> 
                        <span className='mst-in'>MBBS MD (Dermatology)</span>
                      </div>
                      <div className="team-btn-box">
                        <Link to="/contacts" className="btn theam-btn-m">Consult</Link>
                        <Link to="#" className="btn theam-btn-m">View Profile</Link>
                      </div>
                    </div>  
                  </div>
                </div>
                <div className="col-md-6">
                  <div className='shadow-box row'>
                    <div className="col-lg-5 col-md-5 col-sm-5 teamimg">
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/teams-02.png`} alt="slide 3" />
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-7 teamcontent">
                      <h5>Aperna Tej</h5>
                      <p>Senior Physiotherapist with 15+ years of expreance in joint care, musculoskeletal health, and geriatrics</p>
                      <div className='ext-content'>
                        <span className='exp-yers'>15+ Years of Exp</span> 
                        <span className='mst-in'>Physiotherapist</span>
                      </div>
                      <div className="team-btn-box">
                        <Link to="/contacts" className="btn theam-btn-m">Consult</Link>
                        <Link to="#" className="btn theam-btn-m">View Profile</Link>
                      </div>
                    </div>  
                  </div>
                </div>
                
              </div>
            </div>
            <div className="carousel-item">
              <div className="row">              
                <div className="col-md-6">
                  <div className='shadow-box row'>
                    <div className="col-lg-5 col-md-5 col-sm-5 teamimg">
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/teams-03.png`} alt="slide 3" />
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-7 teamcontent">
                      <h5>Dr.Kritika Patel</h5>
                      <p>MBBS MD (Paediatrics), Fellowship in Neonatology Consultant Paediatrician and Neonatologist in several hosptials</p>
                      <div className='ext-content'>
                        <span className='exp-yers'>10+ Years of Exp</span> 
                        <span className='mst-in'>MBBS MD (Paediatrics)</span>
                      </div>
                      <div className="team-btn-box">
                        <Link to="/contacts" className="btn theam-btn-m">Consult</Link>
                        <Link to="#" className="btn theam-btn-m">View Profile</Link>
                      </div>
                    </div>  
                  </div>
                </div>
                <div className="col-md-6">
                  <div className='shadow-box row'>
                    <div className="col-lg-5 col-md-5 col-sm-5 teamimg">
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/teams-05.png`} alt="slide 3" />
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-7 teamcontent">
                      <h5>Dr.Priyanka Apte</h5>
                      <p>MBBS PGDFM (CMC Vellore) Family Physician</p>
                      <div className='ext-content'>
                        <span className='exp-yers'>10+ Years of Exp</span> 
                        <span className='mst-in'>MBBS PGDFM (CMC Vellore)</span>
                      </div>
                      <div className="team-btn-box">
                        <Link to="/contacts" className="btn theam-btn-m">Consult</Link>
                        <Link to="#" className="btn theam-btn-m">View Profile</Link>
                      </div>
                    </div>  
                  </div>
                </div>
                
              </div>
            </div>
            <div className="carousel-item">
              <div className="row">              
                <div className="col-md-6">
                  <div className='shadow-box row'>
                    <div className="col-lg-5 col-md-5 col-sm-5 teamimg">
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/teams-04.png`} alt="slide 3" />
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-7 teamcontent">
                      <h5>Siddharth Sakelle</h5>
                      <p>BPT, Masters in Orthopedic, Sports & Manual Therapy(Australia) Specialised in Sports Physiotherapy</p>
                      <div className='ext-content'>
                        <span className='exp-yers'>20+ Years of Exp</span> 
                        <span className='mst-in'>BPT, Masters in Orthopedic</span>
                      </div>
                      <div className="team-btn-box">
                        <Link to="/contacts" className="btn theam-btn-m">Consult</Link>
                        <Link to="#" className="btn theam-btn-m">View Profile</Link>
                      </div>
                    </div>  
                  </div>
                </div>
                <div className="col-md-6">
                  <div className='shadow-box row'>
                    <div className="col-lg-5 col-md-5 col-sm-5 teamimg">
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/teams-05.png`} alt="slide 3" />
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-7 teamcontent">
                      <h5>Dr.Priyanka Apte</h5>
                      <p>MBBS PGDFM (CMC Vellore) Family Physician</p>
                      <div className='ext-content'>
                        <span className='exp-yers'>10+ Years of Exp</span> 
                        <span className='mst-in'>MBBS PGDFM (CMC Vellore)</span>
                      </div>
                      <div className="team-btn-box">
                        <Link to="/contacts" className="btn theam-btn-m">Consult</Link>
                        <Link to="#" className="btn theam-btn-m">View Profile</Link>
                      </div>
                    </div>  
                  </div>
                </div>
                
              </div>
            </div>



          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselTeam" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselTeam" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      </div>
    </>
  );
}
