import React from 'react';

export default function ExcellenceMedicalService() {
  return (
    <>
        <div className="container mb-5">
            <div className='specialized-section'>
                <h2>Excellence in Medical Service With <span>Passion for Patient Care</span></h2>
                <p className='limit-width'>Our mission is on bring quality primary care close to families engaging them in making their lives healthier.At FamPhy, we understand that your family's health and well-being are of utmost importance. That's why we're dedicated to providing top-notch medical care and home care services that prioritize the comfort and health of your loved ones.</p>
            </div>
            <div className='row about-content'>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className="card">
                        <div className='card-number'>01</div>
                        <div className="card-body">
                            <h3 className="card-title">Our Commitment to Patient Care</h3>
                            <p className="card-text">At the heart of FamPhy is our unwavering commitment to patient care. We believe that every individual deserves personalized attention, compassion, and the highest standard of medical services. When you choose FamPhy, you're choosing a trusted partner in your family's healthcare journey.</p>
                            
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className="card">
                        <div className='card-number'>02</div>
                        <div className="card-body">
                            <h3 className="card-title">Comprehensive Medical Services</h3>
                            <p className="card-text">Our range of comprehensive medical services covers a wide spectrum of healthcare needs. Whether it's routine check-ups, specialized treatments, or online consultations, our team of experienced medical professionals is here to provide the care you deserve.</p>
                            
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className="card">
                        <div className='card-number'>03</div>
                        <div className="card-body">
                            <h3 className="card-title">Putting "Family" in FamPhy</h3>
                            <p className="card-text">We recognize that families play a crucial role in a patient's journey to recovery and well-being. At FamPhy, we encourage family involvement in the care process. We believe that a strong support system and open communication between patients, their families, and our medical team are essential for successful outcomes.</p>
                            
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className="card">
                        <div className='card-number'>04</div>
                        <div className="card-body">
                            <h3 className="card-title">Your Partner in Health</h3>
                            <p className="card-text">FamPhy is more than just a healthcare provider; we're your partner in health. We're here to support your family through every medical challenge, ensuring that you receive the best medical care and home care services available.</p>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

            
        
        
    </>
  );
}
