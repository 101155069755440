import React from 'react';
import SiteLogo from './header/SiteLogo';
import Navbar from './header/Navbar';
import Phone from './header/Phone';

export default function Header() {
  return (
    <div className='main-header'>
      <div className="container">
        <SiteLogo />
        <Navbar />
        <Phone />
      </div>
    </div>
  );
}
