import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';

export default function Specialized() {

  const settings = {
    dots: false, // Show pagination dots
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Default number of items for desktop
    slidesToScroll: 1,
    arrows: true, // Enable navigation arrows
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: true, // Enable arrows on this breakpoint
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: true, // Enable arrows on this breakpoint
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1, // 1 item for mobile screens
          slidesToScroll: 1,
          dots: true,
          arrows: true, // Enable arrows on this breakpoint
        },
      },
    ],
  };
 
  return (
    <>

<div className="container">
<div className='specialized-section'>
          <h2>Specialized & <span>Preventive Care</span></h2>
          <p className='limit-width'>Transform your health with our General & specialized services. Our expert team ensures comprehensive care for a confident lifestyle.</p>
          

          <Slider {...settings}>
            <div className='articles-item'>
                <div className='shadow-box'>
                    <Link to="/our-services">
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/famphy-services-01.jpg`} alt="slide 1" />
                      <h5>Home Visits</h5>
                    </Link>
                </div>
            </div>
            <div className='articles-item'>
                <div className='shadow-box'>
                    <Link to="/our-services">
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/famphy-services-02.jpg`} alt="slide 2" />
                      <h5>Video Consultations</h5>
                    </Link>
                </div>
            </div>
            <div className='articles-item'>
                <div className='shadow-box'>
                    <Link to="/our-services">
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/famphy-services-03.jpg`} alt="slide 3" />
                      <h5>Family Physicians</h5>
                    </Link>
                </div>
            </div>
            <div className='articles-item'>
                <div className='shadow-box'>
                    <Link to="/our-services">
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/famphy-services-04.jpg`} alt="slide 4" />
                      <h5>Lab Tests</h5>
                    </Link>
                </div>
            </div>
            <div className='articles-item'>
                <div className='shadow-box'>
                    <Link to="/our-services/">
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/famphy-services-05.jpg`} alt="slide 4" />
                      <h5>Vaccinations</h5>
                    </Link>
                </div>
            </div>
            
            </Slider>
      </div>
      </div>
    </>
  );
}
