import React from 'react';
import { Link } from 'react-router-dom';

export default function AssociatePartners() {
  return (
    <>


        <div className="container">
            <div className='partners-section'>
                <h2>Associate <span>Partners</span></h2>
                <p className='limit-width'>Specialized and general physicians who have many years of work experience in there respective fields</p>
                <ul className="partners-logo">
                    <li><Link to="#"><img src={`${process.env.PUBLIC_URL}/img/partners-logo-01.png`} alt=''/></Link></li>
                    <li><Link to="#"><img src={`${process.env.PUBLIC_URL}/img/partners-logo-02.png`} alt=''/></Link></li>
                    <li><Link to="#"><img src={`${process.env.PUBLIC_URL}/img/partners-logo-03.png`} alt=''/></Link></li>
                    <li><Link to="#"><img src={`${process.env.PUBLIC_URL}/img/partners-logo-04.png`} alt=''/></Link></li>
                    <li><Link to="#"><img src={`${process.env.PUBLIC_URL}/img/partners-logo-05.png`} alt=''/></Link></li>
                    <li><Link to="#"><img src={`${process.env.PUBLIC_URL}/img/partners-logo-06.png`} alt=''/></Link></li>
                    <li><Link to="#"><img src={`${process.env.PUBLIC_URL}/img/partners-logo-07.png`} alt=''/></Link></li>
                    <li><Link to="#"><img src={`${process.env.PUBLIC_URL}/img/partners-logo-08.png`} alt=''/></Link></li>
                    <li><Link to="#"><img src={`${process.env.PUBLIC_URL}/img/partners-logo-09.png`} alt=''/></Link></li>
                    <li><Link to="#"><img src={`${process.env.PUBLIC_URL}/img/partners-logo-10.png`} alt=''/></Link></li>
                    <li><Link to="#"><img src={`${process.env.PUBLIC_URL}/img/partners-logo-11.png`} alt=''/></Link></li>
                    <li><Link to="#"><img src={`${process.env.PUBLIC_URL}/img/partners-logo-12.png`} alt=''/></Link></li>
                </ul>
            
            
            </div>
        </div>      
    </>
  );
}
