import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { post } from '../../utils/httpClient';

const initialData = {
  fullName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  message: '',
};

const ContactUsForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState(initialData);

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      const response = await post('contact.php', formData);
      console.log(response);
      Swal.fire({
        title: 'Success!',
        html: `<div>
                <h3>Thank you for contacting us!</h3>
                <p>We will get back to you shortly.</p>
              </div>`,
        icon: 'success',
        confirmButtonText: 'OK'
      });
      setFormData(initialData);
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: 'Error!',
        text: 'Some error occured. Please try again',
        icon: 'error',
        confirmButtonText: 'Retry'
      });
    } finally {
      setIsSubmitting(true);
    }
  };

  return (
    <>
    <div className='inquery-form'>
      <h2>Enquiry <span>Form</span></h2>
      {submitted ? (
        <div>
          <h3>Thank you for contacting us!</h3>
          <p>We will get back to you shortly.</p>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div class="form-group">
            <div>
              <label>Full Name *</label>
              <input
                className='form-control'
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                required
              />
            </div>

            <div>
              <label>Last Name *</label>
              <input
                className='form-control'
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>

            <div>
              <label>Email *</label>
              <input
                className='form-control'
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div>
              <label>Phone Number *</label>
              <input
                className='form-control'
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
              />
            </div>

            <div>
              <label>Message *</label>
              <textarea
                className='form-control'
                rows='3'
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <div className='mt-3'>
              <button type="submit" disabled={isSubmitting} className='btn theam-btn-m mw-60'>Submit</button>
            </div>
          </div>
        </form>
      )}
      </div>
    </>
  );
};

export default ContactUsForm;
