import React, { useEffect, useRef } from 'react';

export default function Testimonials() {

    const carouselRef = useRef(null);

    useEffect(() => {
      const handleSlide = (e) => {
        const target = e.relatedTarget;
        const idx = Array.from(target.parentNode.children).indexOf(target);
        const itemsPerSlide = 5;
        const totalItems = document.querySelectorAll('.carousel-item').length;
  
        if (idx >= totalItems - (itemsPerSlide - 1)) {
          const it = itemsPerSlide - (totalItems - idx);
          for (let i = 0; i < it; i++) {
            if (e.direction === 'left') {
              const item = document.querySelectorAll('.carousel-item')[i];
              document.querySelector('.carousel-inner').appendChild(item);
            } else {
              const item = document.querySelectorAll('.carousel-item')[0];
              document.querySelector('.carousel-inner').appendChild(item);
            }
          }
        }
      };
  
      const carouselElement = carouselRef.current;
      carouselElement.addEventListener('slide.bs.carousel', handleSlide);
  
      return () => {
        carouselElement.removeEventListener('slide.bs.carousel', handleSlide);
      };
    }, []);


  return (
    <>
      
      <div className="container" id="testimonials">
        <div className='ourtestimonials-section'>
          <h2>What <span>Our Patients</span> Have To Say</h2>
          <p className='limit-width'>Specialized and general physicians who have many years of work experience in there respective fields</p>
          

      
          <div
            id="carouselTestimonial"
            className="carousel slide"
            data-bs-ride="carousel"
            data-bs-interval="9000"
            ref={carouselRef}
          >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="row">
              <div className="col-md-6">
                  <div className='shadow-box row'>
                    
                    <div className="col-lg-12 col-md-12 col-sm-12 teamcontent">
                      <h5> Ira Singh, IP Extension, New Delhi</h5>
                      <p>I am extremely impressed by FamPhy - not least because of the prompt response to
										a medical emergency. I think it’s a much needed service and completely affordable.  It’s also really reassuring for
										people who have elderly parents to take care of. We are just really grateful we stumbled upon this- thanks to my sister in the U.S- and I have told
										a great many people about it and given them the contact number and website details.</p>
                      <div className='star-review'>
                        <span className='star-span'><i className="fas fa-star"></i></span> 
                        <span className='star-span'><i className="fas fa-star"></i></span>
                        <span className='star-span'><i className="fas fa-star"></i></span>
                        <span className='star-span'><i className="fas fa-star"></i></span>
                        <span className='star-span'><i className="fas fa-star-half-alt"></i></span>                       
                      </div>
                    </div>  
                  </div>
                </div>
                <div className="col-md-6">
                  <div className='shadow-box row'>
                    
                    <div className="col-lg-12 col-md-12 col-sm-12 teamcontent">
                      <h5> Bimla Rani Sachdeva, Palam Vihar, Gurgaon</h5>
                      <p> Our Experience with FamPhy was very good. The doctor was a great guide in our mother’s health matters.
											His home visit was a big relief for us as she was not in the condition 
											to be taken to the hospital. You are providing a valuable service to society</p>
                      <div className='star-review'>
                        <span className='star-span'><i className="fas fa-star"></i></span> 
                        <span className='star-span'><i className="fas fa-star"></i></span>
                        <span className='star-span'><i className="fas fa-star"></i></span>
                        <span className='star-span'><i className="fas fa-star"></i></span>
                        <span className='star-span'><i className="fas fa-star-half-alt"></i></span>                       
                      </div>
                    </div>  
                  </div>
                </div>
                              
              </div>
            </div>
            <div className="carousel-item">
              <div className="row">              
              <div className="col-md-6">
                  <div className='shadow-box row'>
                   
                    <div className="col-lg-12 col-md-12 col-sm-12 teamcontent">
                      <h5>Surjit Singh Madan,  Paharganj, New Delhi</h5>
                      <p>Excellent Doctor , I have not seen a doctor like this in my whole life , He actually saved my father's life</p>
                      <div className='star-review'>
                        <span className='star-span'><i className="fas fa-star"></i></span> 
                        <span className='star-span'><i className="fas fa-star"></i></span>
                        <span className='star-span'><i className="fas fa-star"></i></span>
                        <span className='star-span'><i className="fas fa-star"></i></span>
                        <span className='star-span'><i className="fas fa-star-half-alt"></i></span>                       
                      </div>
                    </div>  
                  </div>
                </div>
                <div className="col-md-6">
                  <div className='shadow-box row'>
                    
                    <div className="col-lg-12 col-md-12 col-sm-12 teamcontent">
                      <h5>Janak Pathak, Raksha Bhawan , New Delh</h5>
                      <p> Doctor was very good. If we feel like we want a doctor again we will definitely get back to you</p>
                      <div className='star-review'>
                        <span className='star-span'><i className="fas fa-star"></i></span> 
                        <span className='star-span'><i className="fas fa-star"></i></span>
                        <span className='star-span'><i className="fas fa-star"></i></span>
                        <span className='star-span'><i className="fas fa-star"></i></span>
                        <span className='star-span'><i className="fas fa-star-half-alt"></i></span>                       
                      </div>
                    </div>  
                  </div>
                </div>
                
              </div>
            </div>
            <div className="carousel-item">
              <div className="row">              
              <div className="col-md-6">
                  <div className='shadow-box row'>
                   
                    <div className="col-lg-12 col-md-12 col-sm-12 teamcontent">
                      <h5>Niloy Debnath , Chanakyapuri, New Delhi</h5>
                      <p>  It was  quite a good experience because FamPhy was the only one who made it possible after we tried several others.</p>
                      <div className='star-review'>
                        <span className='star-span'><i className="fas fa-star"></i></span> 
                        <span className='star-span'><i className="fas fa-star"></i></span>
                        <span className='star-span'><i className="fas fa-star"></i></span>
                        <span className='star-span'><i className="fas fa-star"></i></span>
                        <span className='star-span'><i className="fas fa-star-half-alt"></i></span>                       
                      </div>
                    </div>  
                  </div>
                </div>
                <div className="col-md-6">
                  <div className='shadow-box row'>
                   
                    <div className="col-lg-12 col-md-12 col-sm-12 teamcontent">
                      <h5> Bimla Rani Sachdeva, Palam Vihar, Gurgaon</h5>
                      <p> This is just to say, I'm thankful for the service FamPhy provided. As promised, the doctor visited within an hour.
											The doctor consulted the patient really well. We're extremely satisfied with the service.</p>
                      <div className='star-review'>
                        <span className='star-span'><i className="fas fa-star"></i></span> 
                        <span className='star-span'><i className="fas fa-star"></i></span>
                        <span className='star-span'><i className="fas fa-star"></i></span>
                        <span className='star-span'><i className="fas fa-star"></i></span>
                        <span className='star-span'><i className="fas fa-star-half-alt"></i></span>                       
                      </div>
                    </div>  
                  </div>
                </div>
                
              </div>
            </div>
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselTestimonial" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselTestimonial" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      </div>

    </>
  );
}
