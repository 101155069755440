import React from 'react';
import { Link } from 'react-router-dom';
export default function LabTests() {
  return (
    <>
      <div className='services-item container'>
        <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12 p-0'>
            <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/famphy-services-04.png`} alt="Home Visits" />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 v-align-middle p-0'>
                <div className='services-item-content'>
                <h2>Lab   <span>Tests</span></h2>          
                <p>Lab tests are a critical component of healthcare, helping doctors diagnose, monitor, and treat a wide range of conditions. Whether it’s for routine health screenings, diagnosing an illness, or monitoring a chronic condition, lab tests provide the data needed for accurate medical decisions. With advances in technology, lab tests today are more precise and accessible than ever, offering invaluable insights into your health.</p>
                <Link to="/contacts" className="btn theam-btn-m">Contact Us</Link>
                </div>
            </div>          
        </div>
      </div>
    </>
  );
}
