import React from 'react';
import { Link } from 'react-router-dom';
export default function FamilyPhysicians() {
  return (
    <>
      <div className='services-item container'>
        <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-12 v-align-middle mob-order-2 p-0'>
            <div className='services-item-content'>
              <h2>Family  <span>Physicians</span></h2>          
              <p>Family physicians play a vital role in providing lifelong, comprehensive healthcare for individuals of all ages. From infants to seniors, they offer a wide range of services, managing everything from preventive care to chronic disease management. With their broad medical expertise, family physicians become trusted healthcare partners, focusing on personalized, patient-centered care for every member of the family.</p>
              <Link to="/contacts" className="btn theam-btn-m">Contact Us</Link>
            </div>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 mob-order-1 p-0'>
          <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/famphy-services-03.png`} alt="Home Visits" />
          </div>
        </div>
      </div>
    </>
  );
}
