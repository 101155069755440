import React, { useRef, useEffect, useState } from 'react';


// Sample FAQ data
const faqs = [
    {
        id: 1,
        header: "What is the purpose of a home visit by a doctor?",
        text: `A doctor at home helps you from avoiding hassles of rushing to the hospital, wait in the queue and be at risk of cross infections. Many have multiple chronic health conditions, are frail, elderly and can’t leave the house on a day to day basis.`
    },
    {
        id: 2,
        header: "I asked for a home visit and customer care representative asked me for some questions,why?",
        text: `This is to gain more medical information about your condition, the request for a house visit and to ensure it is the most appropriate course of action.`
    },
    {
        id: 3,
        header: "Why doesn't the doctor provide home visits for everyone who ask for one?",
        text: `FamPhy caters to people with everyday illnesses for which home settings are best suited. Emergencies are better managed in hospital settings.`
    },
    {
        id: 4,
        header: "I don't have transport to get to the hospital. I request a home visit?",
        text: `Yes, this where FamPhy is best suited to help you and your loved ones.`
    },
    {
        id: 5,
        header: "I have been unwell For a number of days but call to doctor late in the day. Is it okay? ",
        text: `Ideally it is better if you are able to call requesting a house visit as early as possible for us to help you better. if you feel unwell later in the day you dont have it in your hands anymore, you shoud opt to contat us.`
    },
    {
        id: 6,
        header: "I have asked for a home visit for My relative. is it okay?",
        text: `Yes as long as the request is appropriate. Please ensure your relative knows that we are coming.`
    },
    {
        id: 7,
        header: "My GP hasn't visited But feels I need to go into hospital. How do they know without having seen me?",
        text: `After having assessed your condition over the phone the GP may feel it more appropriate that you attend hospital without visiting you. This means that it is safer and quicker for you to get the medical attention you need.`
    },
    {
        id: 8,
        header: "Why isn't the doctor I normally see visiting me?",
        text: `Unfortunately the majority of the time you cannot specify the doctor you wish to see for a house visit. It will depend on the doctors allocated for house visits on the day. Our home doctors are equipped with the history of patients that have been seen by us earlier through a very sophisticated electronic medical record system (EMR) are supported by evidence based practice guidelines which helps create standardisation in practice.re better managed in hospital settings.`
    },
    {
        id: 9,
        header: "My pet dog is very friendly. Can you leave my dog in the same room?",
        text: `Ideally pets should be kept in another room before the doctor arrives as it can be a distraction during the consultation. It is important that we can concentrate fully on your medical problems without the distraction of pets running about the room.`
    },
    {
        id: 10,
        header: "My house can be difficult to find?",
        text: `If your house is difficult to find or the street name/house number not signposted it would make it easier if we know this beforehand so we can get better directions about how to find it quickly.`
    },
    {
        id: 11,
        header: "I take a lot of Medications and can never remember which one?",
        text: `Although when performing house visits we have brief medical information about you it can be a good idea to have a list of the medication you take or the medication boxes to make it easier for us to determine the medications you are taking. You can prepare this before we arrive.`
    }

];

// FAQ Component
const FAQ = ({ faq, active, handleToggle }) => {
    const contentEl = useRef(null);
    const { header, id, text } = faq;

    useEffect(() => {
        if (active === id && contentEl.current) {
            contentEl.current.style.height = `${contentEl.current.scrollHeight}px`;
        } else if (contentEl.current) {
            contentEl.current.style.height = "0px";
        }
    }, [active, id]);

    return (
        <>
            <div className="rc-accordion-card">
                <div className="rc-accordion-header">
                    <div
                        className={`rc-accordion-toggle p-3 ${active === id ? 'active' : ''}`}
                        onClick={() => handleToggle(id)}
                    >
                        <h5 className="rc-accordion-title">{header}</h5>
                        <i className={`fa fa-chevron-down rc-accordion-icon ${active === id ? 'rotate' : ''}`}></i>
                    </div>
                </div>
                <div
                    ref={contentEl}
                    className={`rc-collapse ${active === id ? 'show' : ''}`}
                >
                    <div className="rc-accordion-body">
                        <p className='mb-0'>{text}</p>
                    </div>
                </div>
            </div>
        </>
    );
};

// App Component
const App = () => {
    const [active, setActive] = useState(null);

    const handleToggle = (id) => {
        setActive(active === id ? null : id);
    };

    return (
        <>
            <div className='Inner-banner'>
        <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/inner-banner-04.jpg`} alt="Home Visits" />
      </div>
            <div className='faq-page'>
                <div className="container mb-5">
                    <div className='specialized-section'>
                        <h2> <span>FAQ's</span></h2>
                        <p className='limit-width'>At FamPhy, our commitment to providing exceptional medical care is driven by the expertise.</p>
                    </div>
                </div>
                <div className="container-fluid mt-5 mb-5">
                    <div className="row justify-content-center">
                        <div className="col-md-8 mt-2">
                            <div className="card">
                                <div className="card-body">
                                    {faqs.map((faq) => (
                                        <FAQ
                                            key={faq.id}
                                            faq={faq}
                                            active={active}
                                            handleToggle={handleToggle}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default App;
