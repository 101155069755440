import React from 'react';
import { Link } from 'react-router-dom';

export default function ContactAddress() {
  return (
    <>
      <h2>Contact <span>Us</span></h2>
      <p><i className="fas fa-home mr-3"></i> Lower Ground Floor, Mall of Bengaluru, Bhartiya City, Kannuru, Bengaluru, 560064</p>
      <p><i className="fas fa-envelope mr-3"></i> hello@famphy.com</p>
      <p><i className="fas fa-phone mr-3"></i> +91 98 6833 6833</p>
      
      <div className="social-media mb-5">
        <Link to="/"><i className="fab fa-facebook-f"></i></Link>
        <Link to="/"><i className="fab fa-twitter"></i></Link>
        <Link to="/"><i className="fab fa-google"></i></Link>
        <Link to="/"><i className="fab fa-instagram"></i></Link>
      </div>

      <iframe
        title="Famphy Location on Google Maps" // Add a title here
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15276330.137716739!2d77.2664842!3d20.8085644!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d185fee712f1b%3A0x3770dce499fd6868!2sFamphy-%20The%20Physician%20On%20Call!5e0!3m2!1sen!2sin!4v1726682306788!5m2!1sen!2sin"
        width="100%"
        height="300"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </>
  );
}
