import React from 'react';
import WelcomeFamphyClinics from './clinicvisit/WelcomeFamphyClinics';
import FamilyPhysicians from './clinicvisit/FamilyPhysicians';
import ClinicLabTests from './clinicvisit/ClinicLabTests';
import ClinicPhysiotheripy from './clinicvisit/ClinicPhysiotheripy';
import ClinicVaccinations from './clinicvisit/ClinicVaccinations';
import ClinicECG from './clinicvisit/ClinicECG';
import TreadmillTest from './clinicvisit/TreadmillTest';
import ClinicAudiometry from './clinicvisit/ClinicAudiometry';
import ClinicNebulization from './clinicvisit/ClinicNebulization';

export default function ClinicVisit() {
  return (
    <>
      <div className='Inner-banner'>
        <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/inner-banner-02.jpg`} alt="Home Visits" />
      </div>
        <div className='clinic-visit'>
            <WelcomeFamphyClinics />
            <FamilyPhysicians />
            <ClinicLabTests />
            <ClinicPhysiotheripy />
            <ClinicVaccinations />
            <ClinicECG />
            <TreadmillTest />
            <ClinicAudiometry />
            <ClinicNebulization />
        </div>
    </>
  );
}
