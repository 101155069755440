import React from 'react';
import { Link } from 'react-router-dom';
export default function ClinicNebulization() {
  return (
    <>
      <div className='services-item container'>
            <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12 p-0'>
                <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/clinic-visit-05.png`} alt="Home Visits" />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 v-align-middle p-0'>
                    <div className='services-item-content'>
                    <h2><span>Nebulization</span></h2>          
                    <p>At FamPhy, we offer professional nebulization services to help individuals with respiratory conditions manage their symptoms effectively. Nebulization is a crucial treatment option for conditions like asthma, chronic obstructive pulmonary disease (COPD), and other respiratory ailments. Our experienced healthcare team is dedicated to providing compassionate care and ensuring your comfort throughout the nebulization process.</p>
                    <Link to="/contacts" className="btn theam-btn-m">Contact Us</Link>
                    </div>
                </div>
                
            </div>
        </div>
    </>
  );
}
