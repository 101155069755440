import React from 'react';

export default function SubscribeNewsletter() {
  return (
    <>
        <div className="container">
            <div className='newsletters-section'>
                <h2>Subscribe To Our <span>Newsletter</span></h2>
                <p className='limit-width'>Unlock special offers, service updates, tips and more!</p>
                <div className="newsletterForm">
                    <input type="text" placeholder="Enter email address" />
                    <button className='btn theam-btn-m'>Subscribe</button>
                </div>
            </div>
        </div>
    </>
  );
}
