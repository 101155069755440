import React from 'react';
import Specialized from './home-page/Specialized';
import HomeVisits from './services/HomeVisits';
import VideoConsultations from './services/VideoConsultations';
import FamilyPhysicians from './services/FamilyPhysicians';
import LabTests from './services/LabTests';
import SeniorCitizens from './services/SeniorCitizens';
import Vaccinations from './services/Vaccinations';
import InnerHeroBanner from './InnerHeroBanner';

export default function OurServices() {
  return (
    <>
      <InnerHeroBanner />
      <div className='our-services'>
        <Specialized />
        <HomeVisits />
        <VideoConsultations />
        <FamilyPhysicians />
        <LabTests />
        <SeniorCitizens />
        <Vaccinations />
      </div>
    </>
  );
}
