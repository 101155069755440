import React from 'react';
import { Link } from 'react-router-dom';

export default function SeniorCitizens() {
  return (
    <>
        <div className='services-item container'>
        <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-12 v-align-middle mob-order-2 p-0'>
            <div className='services-item-content'>
              <h2>Senior  <span>Citizens</span></h2>          
              <p>As we age, regular health checkups become increasingly important to maintain a high quality of life. Senior citizens face unique health challenges that require specialized care and attention. Routine doctor visits are essential to monitor chronic conditions, detect potential issues early, and ensure that seniors receive the preventive care they need to stay healthy and active. By prioritizing regular checkups, seniors can enjoy their later years with confidence and peace of mind.</p>
              <Link to="/contacts" className="btn theam-btn-m">Contact Us</Link>
            </div>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 mob-order-1 p-0'>
          <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/famphy-services-05.png`} alt="Home Visits" />
          </div>
        </div>
      </div> 
    </>
  );
}
