import React from 'react';
import siteLogo from '../../assets/Logo.png';
import { Link } from 'react-router-dom';
export default function logo() {
  return (
    <div className="sitelogo-box">
        <Link className="nav-link" to="/"><img src={siteLogo} alt="Famphy Logo" className="sitelogo" /> </Link>     
    </div>
  );
}
