import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';


const settings = {
    dots: true, // Show pagination dots
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Default number of items for desktop
    slidesToScroll: 1,
    arrows: true, // Enable navigation arrows
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: true, // Enable arrows on this breakpoint
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: true, // Enable arrows on this breakpoint
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1, // 1 item for mobile screens
          slidesToScroll: 1,
          dots: true,
          arrows: true, // Enable arrows on this breakpoint
        },
      },
    ],
  };

export default function LatestArticles() {   

  return (
    <>

<div className="container">
<div className='articles-section'>
          <h2>Read Our <span>Latest Articles</span></h2>
          <p className='limit-width'>Transform your health with our General & specialized services. Our expert team ensures comprehensive care for a confident lifestyle.</p>
          

      
          <Slider {...settings}>
            <div className='articles-item'>
                <div className='shadow-box'>
                    <Link to="#">
                    <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/articles-img-01.png`} alt="slide 1" />
                    <p><strong>Body, Clinic</strong></p>
                    <h5>The Lifesaving Importance of Regular...</h5>
                    <p>In our fast-paced world, many of us often overlook one of the most crucial aspects of our lives - our health. We tend to prioritiz...</p>
                    <p> November 2, 2023 by FamPhy Admin</p>
                    </Link>
                </div>
            </div>
            <div className='articles-item'>
                <div className='shadow-box'>
                    <Link to="#">
                        <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/articles-img-02.png`} alt="slide 2" />
                        <p><strong>Clinic, Elder Care, Home Visit</strong></p>
                        <h5>What to Expect During Your First Appointment...</h5>
                        <p>Visiting a family doctor for the first time can be intimidating, especially if you are not sure what to expect. However,...</p>
                        <p>October 26, 2023 2 by FamPhy Admin</p>
                    </Link>
                </div>
            </div>
            <div className='articles-item'>
                <div className='shadow-box'>
                    <Link to="#">
                    <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/articles-img-03.png`} alt="slide 2" />
                    <p><strong>Clinic, Medical, Treatment</strong></p>
                    <h5>ELEPHANT STORY - A doctor's perspective</h5>
                    <p>Once upon a time, there lived six blind men in a village. One day the villagers told them, "Hey, there is an elephant in the village...</p>
                    <p>October 8, 2023 by FamPhy Admin</p>
                    </Link>
                </div>
            </div>
            <div className='articles-item'>
                <div className='shadow-box'>
                    <Link to="#">
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/articles-img-04.png`} alt="slide 4" />
                      <p><strong>Clinic, Medical, Procedures, Treatment</strong></p>
                    <h5>Dr. Soumik Kalita pushing for a person...</h5>
                    <p>In a time when the prevailing trend across the nation leans towards specialized and tertiary healthcare institutions, renowned...</p>
                    <p> October 6, 2023 by FamPhy Admin</p></Link>
                </div>
            </div>
            <div className='articles-item'>
                <div className='shadow-box'>
                    <Link to="#">
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/articles-img-05.png`} alt="slide 4" />
                      <p><strong>Clinic, Eye Care, Medical</strong></p>
                    <h5>COMPUTER VISION SYNDROME: General...</h5>
                    <p>With technological advancements in the 21st century, we are surrounded by digital screens all the time, be it computers,...</p>
                    <p> October 6, 2023 Я by FamPhy Admin</p></Link>
                </div>
            </div>
            <div className='articles-item'>
                <div className='shadow-box'>
                    <Link to="#">
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/articles-img-06.png`} alt="slide 4" />
                      <p><strong>Body, Clinic, Medical</strong></p>
                    <h5>FOOD POISONING: A general body illness</h5>
                    <p>You wake up in the morning and rush to your bathroom vomiting and you feel diarrhea coming in too. Rewind back to...</p>
                    <p> October 6, 2023 by FamPhy Admin</p></Link>
                </div>
            </div>
            
          </Slider>
      </div>
      </div>
      
    </>
  );
}
