import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <>
            <footer className="main-footer">
                <div className="container">
                    <section>
                        <div className="row mt-3 mb-5">
                            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold">QUICK LINKS</h6>
                                <ul>
                                    <li><Link to="/">Terms and Conditions</Link></li>
                                    <li><Link to="/">Privacy Policy</Link></li>
                                    <li><Link to="/our-services">Services</Link></li>
                                    <li><Link to="/">Legal Documents</Link></li>
                                    <li><Link to="/contacts">Enquiry</Link></li>
                                </ul>
                            </div>
                            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold">COMPANY</h6>
                                <ul>
                                    <li><Link to="/about-us">About FamPhy</Link></li>
                                    <li><Link to="/">News & Media</Link></li>
                                    <li><Link to="/">Testimonials</Link></li>
                                    <li><Link to="/faq">FAQ</Link></li>
                                </ul>
                            </div>
                            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold">OUR LOCATIONS</h6>
                                <ul>
                                    <li><Link to="/">Delhi</Link></li>
                                    <li><Link to="/">Gurugram</Link></li>
                                    <li><Link to="/">Bengaluru</Link></li>
                                    <li><Link to="/">Ghaziabad</Link></li>
                                    <li><Link to="/">Noida</Link></li>
                                    <li><Link to="/">Faridabad</Link></li>
                                </ul>
                            </div>
                            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                                <h6 className="text-uppercase fw-bold">CONTACT US</h6>
                                <p><i className="fas fa-home mr-3"></i> Lower Ground Floor,
                                    Mall of Bengaluru, Bhartiya City,
                                    Kannuru, Bengaluru, 560064</p>
                                <p><i className="fas fa-envelope mr-3"></i> hello@famphy.com</p>
                                <p><i className="fas fa-phone mr-3"></i> +91 98 6833 6833</p>
                                <div className="social-media">
                                    <Link to="https://www.facebook.com/famphydocs/"><i className="fab fa-facebook-f"></i></Link>
                                    <Link to="https://x.com/famphy1"><i className="fab fa-twitter"></i></Link>
                                    <Link to="https://www.linkedin.com/in/famphy-the-family-physician-network-90a20717a/"><i class="fab fa-linkedin-in"></i></Link>
                                    <Link to="/"><i className="fab fa-instagram"></i></Link>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="copy-right">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-lg-6 col-xl-6 mx-auto">
                                Copyright © <Link to="/"> FamPhy.com</Link> 2024. All Rights Reserved
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-6 mx-auto text-end">
                                <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/payment-icon.png`} alt="Payment Options" />
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};