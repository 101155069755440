import React from 'react';
import HeroBaner from './home-page/HeroBaner';
import Specialized from './home-page/Specialized';
import OurTeam from './home-page/OurTeam';
import Testimonials from './home-page/Testimonials';
import AssociatePartners from './home-page/AssociatePartners';
import LatestArticles from './home-page/LatestArticles';
import SubscribeNewsletter from './home-page/SubscribeNewsletter';

export default function Home() {
  return (
    <>
      <HeroBaner />
      <Specialized />
      <OurTeam />
      <Testimonials />
      <AssociatePartners />
      <LatestArticles />
      <SubscribeNewsletter />
    </>
  );
}
