import React from 'react';
import { Link } from 'react-router-dom';
export default function ClinicVaccinations() {
  return (
    <>
        <div className='services-item container'>
        <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12 p-0'>
            <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/famphy-services-06.png`} alt="Home Visits" />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 v-align-middle p-0'>
                <div className='services-item-content'>
                <h2><span>Vaccinations</span></h2>          
                <p>We provide safe and easy vaccinations</p>
                <Link to="/contacts" className="btn theam-btn-m">Contact Us</Link>
                </div>
            </div>          
        </div>
      </div>
    </>
  );
}
