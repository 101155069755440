import React from 'react';
import { Link } from 'react-router-dom';
export default function Vaccinations() {
  return (
    <>
              <div className='services-item container'>
        <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12 p-0'>
            <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/famphy-services-06.png`} alt="Home Visits" />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 v-align-middle p-0'>
                <div className='services-item-content'>
                <h2><span>Vaccinations</span></h2>          
                <p>Vaccinations are one of the most effective ways to protect individuals and communities from infectious diseases. From infancy to old age, vaccines help prevent illnesses that can lead to serious complications or even death. By strengthening the body’s immune response, vaccinations not only protect the person receiving the vaccine but also contribute to herd immunity, keeping the most vulnerable populations safe. Staying up to date with recommended vaccines is essential for maintaining long-term health.</p>
                <Link to="/contacts" className="btn theam-btn-m">Contact Us</Link>
                </div>
            </div>          
        </div>
      </div>
    </>
  );
}
