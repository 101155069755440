// App.js
import './assets/css/teme-style.css';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './components/Home';
import OurServices from './components/OurServices';
import ClinicVisit from './components/ClinicVisit';
import AboutUs from './components/AboutUs';
import FAQ from './components/FAQ';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ContactUs from './components/ContactUs';
import AppointmentForm from './components/BookAppointment';
function App() {

    return (
        <>
            <Router>
                <Header />
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/our-services" element={<OurServices />} />
                    <Route exact path="/clinic-visit" element={<ClinicVisit />} />
                    <Route exact path="/about-us" element={<AboutUs />} />
                    <Route exact path="/faq" element={<FAQ />} />
                    <Route exact path="/contacts" element={<ContactUs />} />
                    <Route exact path="/book-appointment" element={<AppointmentForm />} />
                </Routes> 
                <Footer />               
            </Router>  
        </>
    );
}
export default App;
